import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { auth } from '../../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import './LoginPageMobile.css';
import ios from '../../images/ios.png';
import android from '../../images/android.png';

const LoginPageMobile = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const packageName = 'net.podana.mobile';

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            setPopupMessage(`${t('error')}: ${error.message}`);
            setShowPopup(true);
        } finally {
            setLoading(false);
        }
    };

    const handleImageIos = () => {
        const appUrl = `https://apps.apple.com/app/6467253982`; // Substitua <YOUR_APP_ID> pelo ID do seu aplicativo na App Store
        window.open(appUrl, '_blank');
    };
    
    const handleImageAndroid = () => {
        const appUrl = `https://play.google.com/store/apps/details?id=${packageName}`; // Abre a Play Store
        window.open(appUrl, '_blank');
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <div className="login-container">
            <img 
                src={ios} 
                alt="ios download" 
                className="login-image" 
                onClick={handleImageIos} 
            />
            <img 
                src={android}
                alt="Image 2" 
                className="login-image" 
                onClick={handleImageAndroid} 
            />
            {/*<input
                type="email"
                placeholder={t('email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="login-input"
            />
            <input
                type="password"
                placeholder={t('password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-input"
            />
            <button 
                className="login-button" 
                onClick={handleLogin}
                disabled={loading}
            >
                {loading ? t('loading') : t('login')}
            </button>
            <p className="download-text">
                {t('to_create_account_download_app_or_use_desktop')}
            </p>
            {showPopup && (
                <div className="popup-message">
                    <div className="popup-content-message">
                        <p>{popupMessage}</p>
                        <button onClick={closePopup} className="close-popup-message">{t('close')}</button>
                    </div>
                </div>
            )}*/
            }
        </div>
    );
};

export default LoginPageMobile;