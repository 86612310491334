import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import LoginPage from './components/LoginPage';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import MainView from './components/MainView';
import './App.css';
import LoginPageMobile from './components/mobile/LoginPageMobile';
import Premium from './components/Premium';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [premiumTime, setPremiumTime] = useState(null);
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        
        try {
          await user.reload();
          setIsAuthenticated(true);
          setUserId(user.uid);
          
          const userDocRef = doc(db, 'users', user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setPremiumTime(userData.premiumTime || "0");
          }
        } catch (error) {
          
          signOut(auth);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    });
    
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Verifica a largura da tela na montagem do componente

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-bar"></div>
      </div>
    );
  }  

  if (isMobileScreen) {
    return (
      <Router>
        <Routes>
          <Route
            path="/"
            element={isAuthenticated ? <Navigate to="/user" /> : <LoginPageMobile />}
          />
          <Route path="/terms-of-service" element={<TermsOfService/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route
            path="/user"
            element={
              isAuthenticated ? (
                <Premium isMobileScreen={isMobileScreen} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Routes>
      </Router>
    )
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to="/user" /> : <LoginPage />}
        />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/user"
          element={
            isAuthenticated ? (
              <MainView userId={userId} premiumTime={premiumTime} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;